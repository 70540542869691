export default class Header {
  topBar = document.querySelector('#topbar')
  menuButton = document.querySelector('.menu-button-wrapper')
  anim = null
  oldScroll = null
  initialClasses = null

  constructor(scrollTreshhold = 100){
    this.scrollTreshhold = scrollTreshhold
  }

  init(){
    this.oldScroll = document.querySelector('.main').scrollTop
    this.scrollTest()
    this.initialClasses = this.topBar.className

    document.querySelector('.main').addEventListener('scroll', () => this.scrollTest() )
  }

  scrollTest = () => {
    const newScroll = document.querySelector('.main').scrollTop

    if(!this.topBar.classList.contains('menu')){
      if(newScroll > this.scrollTreshhold){
        this.small()
      }else{
        this.big()
      }
      
      if(this.oldScroll > newScroll){
        this.up()
      } else if(this.oldScroll < newScroll && newScroll > this.scrollTreshhold){
        this.down()
      }

      if(newScroll < this.scrollTreshhold){
        this.up(true)
      }
    }

    // console.log(newScroll);
    // console.log(document.querySelector('.main').scrollHeight);
    // console.log(document.querySelector('.main').clientHeight);

    if (newScroll >= document.querySelector('.main').scrollHeight - document.querySelector('.main').clientHeight) {
        this.up()
    }

    this.oldScroll = newScroll
  }

  down(){
    this.topBar.classList.add('-translate-y-full', 'lock-anim')
    this.menuButton.classList.add('-translate-y-full', 'lock-anim')
  }

  up(force = false){
    if(window.matchMedia('(min-width:576px)').matches || force){
      this.topBar.classList.remove('-translate-y-full', 'lock-anim')
    }
    this.menuButton.classList.remove('-translate-y-full', 'lock-anim')
  }

  small(){
    if(window.matchMedia('(min-width:576px)').matches){
      this.topBar.classList.add('add-bg')
      this.menuButton.classList.add('add-bg')
    }
  }

  big(){
    this.topBar.classList.remove('add-bg')
    this.menuButton.classList.remove('add-bg')
  }

  menuToggle(){
    if(this.topBar.classList.contains('menu')){
      this.topBar.className = this.topBar.prevClasses
      this.menuButton.className = this.topBar.prevClasses
    }else{
      this.topBar.prevClasses = this.topBar.className
      this.topBar.classList.add('menu', 'add-bg')
      this.topBar.classList.remove('-translate-y-full', 'lock-anim')

      this.menuButton.prevClasses = this.topBar.className
      this.menuButton.classList.add('menu', 'add-bg')
      this.menuButton.classList.remove('-translate-y-full', 'lock-anim')
    }
  }

  normalHeader(){
    this.topBar.white = false
    this.topBar.classList.remove('white')
  }

  revert = () => {
    this.topBar.className = this.initialClasses
    this.normalHeader()
    this.big()
    if(this.anim){
      this.anim.seek(0).invalidate()
    }
  }
}