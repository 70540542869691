// const Plyr = require('../vendor/plyr/plyr.polyfilled.js');
// import Plyr from 'plyr';

document.addEventListener('DOMContentLoaded', function () {
  let videoPlayers = document.querySelectorAll('.sign-language-video');
  let videoLinks = document.querySelectorAll('.sign-language-link');

  videoPlayers = Array.prototype.slice.call(videoPlayers);
  videoLinks = Array.prototype.slice.call(videoLinks);

  // const supported = Plyr.supported('video', 'html5', true);

  if (videoLinks) {
    videoLinks.map((link) => {
      link.addEventListener(
        'click',
        function (evt) {
          evt.stopImmediatePropagation();
          // evt.preventDefault();

          let videoElem = document.querySelector(
            "[data-video-id='" + evt.currentTarget.dataset.target + "']"
          );

          if (videoElem.hasClass('hidden')) {
            videoElem.classList.remove('hidden');

            TweenMax.to(videoElem, 0.6, {
              height: videoElem.firstElementChild.offsetHeight + 20,
            });
          } else {
            TweenMax.to(videoElem, 0.6, {
              height: 0,
              onComplete: function () {
                videoElem.classList.add('hidden');
              },
            });
          }
        },
        false
      );
    });
  }

  if (videoPlayers) {
    videoPlayers.map((player) => {
      let videoPlayer = new Plyr(player);

      // videoPlayer.on('ready', event => {

      // });
    });
  }
});
