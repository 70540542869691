import '@babel/polyfill'
import 'custom-event-polyfill'
import '../css/index.pcss'
import browserUpdate from 'browser-update'
import Header from './components/Header'
import VanillaTilt from 'vanilla-tilt'
import Menu from './components/Menu'
import SignLanguageVideo from './components/SignLanguageVideo'

if ('NodeList' in window && !NodeList.prototype.forEach) {
  // console.info('polyfill for IE11');
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

Element.prototype.hasClass = function (className) {
  return this.className && new RegExp('(^|\\s)' + className + '(\\s|$)').test(this.className);
};

window.addEventListener('load', () => {
  document.querySelector('.preloader').remove()
})

browserUpdate({
  required: {
      e:-2,
      i:11,
      f:-3,
      o:-3,
      s:10.1,
      c:"64.0.3282.16817",
      samsung: 7.0,
      vivaldi: 1.2
  },
  insecure:true
})

const header = new Header()
header.init()
document.body.classList.add('opacity-0')

if(!window.isMobile){
  document.querySelectorAll('.button').forEach((el) => {
    VanillaTilt.init(el, {
      glare: true,
      max: 5,
      speed: 600,
      perspective: 100,
      'max-glare': 0.8
    })
  });

  document.querySelectorAll('.long-button').forEach((el) => {
    VanillaTilt.init(el, {
      glare: true,
      max: 5,
      speed: 600,
      perspective: 1000,
      'max-glare': 0.8
    })
  })
}

const menu = new Menu()
menu.init()