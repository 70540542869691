import { TweenMax, TimelineMax } from 'gsap/TweenMax'
import CustomEase from '../vendor/CustomEase'

class Menu {
  targetEl = document.getElementById('main')
  constructor(){}

  init(){
    const ease = CustomEase.create("custom", "M0,0 C0.104,0.204 0.126,1 1,1")
    const button = document.querySelector('.menu-button')
    const menu = document.querySelector('.main-menu')
    const menuItems = []
    menu.querySelectorAll('.main-menu-item').forEach(el => {
      menuItems.push(el)
      if(el.nextElementSibling && el.nextElementSibling.classList.contains('sub-links')){
        el.nextElementSibling.querySelectorAll('.main-menu-item-sub').forEach(sub => menuItems.push(sub))
      }
    })
    const menuTween = TweenMax.fromTo(menu, 0.6, {
      x: '100%',
      y: 0,
      zIndex: 40
    }, {
      zIndex: 40,
      x: '0%',
      y: 0,
      paused: true,
      ease
    })

    const timeline = new TimelineMax({ paused: true })
    timeline.staggerFromTo(menuItems, 0.6, {
      y: 100,
      opacity: 0
    }, {
      y: 0,
      opacity: 1,
      delay: 0.4,
      ease
    }, 0.03)

    const buttonClass = button.classList
    button.addEventListener('click', () => {
      if(buttonClass.contains('open')){
        menuTween.reverse()
        setTimeout(() => {
          timeline.pause(0)
        }, 600)
      }else{
        menuTween.play()
        timeline.play()
      }
      button.parentElement.classList.toggle('open')
      buttonClass.toggle('open')
    })

    menu.querySelectorAll('.main-menu-item-sub').forEach(el => {
      el.addEventListener('click', () => {
        menuTween.reverse()
        setTimeout(() => {
          timeline.pause(0)
        }, 600)
        button.parentElement.classList.toggle('open')
        buttonClass.toggle('open')
      })
    })
  }
}

export default Menu